import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/sobre",
    name: "sobre",
    component: () =>
      import(/* webpackChunkName: "sobre" */ "../views/Sobre.vue"),
  },
  // {
  //   path: "/servico",
  //   name: "servico",
  //   component: () =>
  //     import(/* webpackChunkName: "servico" */ "../views/Servico.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
