<template>
  <div>
    <span class="hidesmall">
      <v-card class="mx-auto card-shandows card-body" height="450">
        <v-row style="margin: auto; height: 100%">
          <v-col cols="4">
            <div style="display: flex; justify-content: center; height: 100%">
              <div class="contato-image">
                <v-img contain :src="card.imagem" class="my-10" />
              </div>
            </div>
          </v-col>
          <v-col cols="8">
            <div style="display: block; justify-content: center; height: 100%">
              <v-card-text style="padding-left:3em" class="card-config white-title">
                {{ card.titulo }}
              </v-card-text>
              <v-card-subtitle class="texto-card" style="color: #e2f5fd; padding: 3em 1em; text-align: center;">
                <div style="padding:0.2em;font-size: 2em !important;color:#e2f5fd;">Nossa equipe esta pronta para atender você:</div>
                <onne-contato-telefone telefone="51 99311-1243" />
                <onne-contato-telefone telefone="51 99724-2304" />
                <onne-contato-email email="techonnetecnologia@gmail.com" />
              </v-card-subtitle>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </span>
    <span class="showsmall">
      <v-card class="espaco-card mx-auto card-shandows card-body" style="min-height: 400px;">
        <div style="display: flex; justify-content: center">
          <div class="onne-card-icon onne-negative-top">
            <v-img contain :src="card.imagem" class="my-10" />
          </div>
        </div>
        <v-card-text class="text-center card-config white-title">
          {{ card.titulo }}
        </v-card-text>
        <v-card-subtitle class="texto-card" style="color: #e2f5fd; padding:1em 1em; text-align: center;">
          <div style="padding:0.2em;font-size: 2em !important;color:#e2f5fd;">Nossa equipe esta pronta para atender você:</div>
          <onne-contato-telefone telefone="51 99311-1243" />
          <onne-contato-telefone telefone="51 99724-2304" />
          <onne-contato-email email="techonnetecnologia@gmail.com" />
        </v-card-subtitle>
      </v-card>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    card: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.card-config {
  color: #e2f5fd;
  font-size: 46px !important;
  font-weight: bold;
  margin-top: 12px;
}
 
.contato-image {
  display: flex;
  place-content: center;
  background-color: #f9b02c;
  margin: 0px 12% 0% 10%;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  margin: auto;
}
.texto-card {
  line-height: 2em;
  font-size: 1em;
  text-align: justify;
  padding: 0px 24px;
}
.card-shandows {
  box-shadow: 19px 18px 17px 3px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}
.white-title {
  font-size: 36px !important;
  color:#e2f5fd!important;
}
.card-body {
  background: rgb(236,235,255);
  color: #e2f5fd;
  background: linear-gradient(61deg, #08090c 0%, #182946 36%, #123b7c 100%);
}

.showsmall {
  display: none;
}
.hidesmall {
  display: block;
}
@media screen and (max-width: 1264px) {
  .showsmall {
    display: block !important;
  }
  .hidesmall {
    display: none !important;
  }
  .espaco-card {
    margin: 6rem;
  }
}
</style>
