
import logo_instagram from "@/assets/logo-instagram.svg";
import logo_facebook from "@/assets/logo-facebook.svg";
import Vue from "vue";

export default Vue.extend({
  props: {
    ig: {
      type: String,
      required: true,
    },
    fb: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    logo_instagram,
    logo_facebook,
  }),

  methods: {
    openIG: function () {
      let url = "https://www.instagram.com/" + this.ig;
      window.open(url);
    },
    openFB: function () {
      let url = "https://www.facebook.com/" + this.fb;
      window.open(url);
    },
  },
});
