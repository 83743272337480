
import Vue from "vue";

export default Vue.extend({
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
});
