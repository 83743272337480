import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";

Vue.config.productionTip = false;

const requireComponent = require.context(
  // O caminho relativo da pasta de componentes
  "./components",
  // Se deve ou não olhar subpastas
  true,
  // Expressão regular para localizar nomes de componentes base
  /Onne[A-Z]\w+\.(vue|js)$/
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
requireComponent.keys().forEach((fileName: any) => {
  // Pega a configuração do componente
  const componentConfig = requireComponent(fileName as string);

  // Obtém nome em PascalCase do componente
  const componentName = upperFirst(
    camelCase(
      // Obtém o nome do arquivo, independentemente da profundidade da pasta
      fileName
        .split("/")
        .pop()
        .replace(/\.\w+$/, "")
    )
  );

  // Registra o componente globalmente
  Vue.component(
    componentName,
    // Olha para as opções em `.default`, existentes
    // se o componente foi exportado com `export default`,
    // caso contrário usa o módulo raiz.
    componentConfig.default || componentConfig
  );
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
