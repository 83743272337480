
import Vue from "vue";

export default Vue.extend({
  data: () => {
    return {
      menuDisponivel: [
        {
          titulo: "Início",
          nome_rota: "home",
          icone: "mdi-home",
        },
        {
          titulo: "Sobre",
          nome_rota: "sobre",
          icone: "mdi-chart-donut",
        },
        // {
        //   titulo: "Serviços",
        //   nome_rota: "servico",
        //   icone: "mdi-tools",
        // },
      ],
    };
  }
});
