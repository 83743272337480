
import logo_telegram from "@/assets/logo_telegram.svg";
import logo_whatsapp from "@/assets/logo_whatsapp.svg";
import Vue from "vue";

export default Vue.extend({
  props: {
    telefone: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    logo_telegram,
    logo_whatsapp,
  }),

  methods: {
    openWhatsapp: function () {
      let fone = "55" + this.telefone.replace(/\D/g, "");
      let url = "https://api.whatsapp.com/send?phone=" + fone;
      window.open(url);
    },
    openTelegram: function () {
      let url = "https://t.me/RodrigoMichel";
      window.open(url);
    },
  },
});
