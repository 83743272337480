
import Vue from "vue";
import contact from "../assets/contact.png";

export default Vue.extend({
  data: () => {
    return {
      imagem: contact,
    };
  },
});
