
import Vue from "vue";

export default Vue.extend({
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  data(): unknown {
    return {};
  },
  methods: {},
});
